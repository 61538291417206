<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="72"
      app
      dark
      permanent
      class="flex-0-0"
    >
      <template #prepend>
        <v-list-item two-line>
          <nuxt-link to="/admin">
            <img src="/logo.svg" class="w-20 mx-4">
          </nuxt-link>

          <v-list-item-content>
            <nuxt-link to="/admin">
              <h2 class="text-h6 white--text">
                EOwn CMS
              </h2>
            </nuxt-link>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list dense dark class="menu px-4">
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.headline" :key="`subheader-${index}`" class="px-0 text-uppercase opacity-50">
            {{ mini ? '' : item.headline }}
          </v-subheader>

          <!-- 1st Level without group -->
          <v-list-item
            v-else-if="item.to"
            :key="`list-item-${index}`"
            :to="item.to"
            active-class="primary opacity-100"
            class="my-3 rounded overflow-hidden opacity-80"
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item-group v-if="Array.isArray(item.children)" :key="`list-item-group-${index}`">
            <template v-for="(subItem) in item.children">
              <!-- 1st Level with group -->
              <v-list-item
                v-if="subItem.to"
                :key="`list-item-${subItem.text}`"
                :to="subItem.to"
                active-class="primary opacity-100"
                class="my-3 rounded overflow-hidden opacity-80"
              >
                <v-list-item-icon v-if="subItem.icon">
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- 2nd Level -->
              <v-list-group
                v-if="Array.isArray(subItem.children)"
                :key="`list-group-${subItem.text}`"
                :prepend-icon="subItem.icon"
                no-action
                class="my-3 rounded overflow-hidden opacity-80 white--text"
              >
                <template #activator>
                  <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                </template>

                <v-list-item
                  v-for="(subSubItem) in subItem.children"
                  :key="subSubItem.text"
                  :to="subSubItem.to"
                  active-class="primary"
                  class="my-3 rounded overflow-hidden"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ subSubItem.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list-item-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      absolute
      app
      flat
      class="app-bar px-6"
      color="white"
      height="56"
    >
      <v-btn icon default @click.stop="mini = !mini">
        <v-icon>menu</v-icon>
      </v-btn>

      <v-spacer />

      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template #activator="{ attrs, on }">
          <v-avatar size="30" v-bind="attrs" v-on="on">
            <img :src="require('~/assets/images/avatar.png')">
          </v-avatar>
        </template>

        <v-list>
          <v-list-item class="px-5">
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1">
                {{ $helpers.get($auth, 'user.profile.fullName', 'EOwn Admin') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption">
                {{ $helpers.get($auth, 'user.email') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list>
          <v-list-item class="px-5 text-subtitle-2" to="/change-password">
            <v-list-item-content>
              Change Password
            </v-list-item-content>
          </v-list-item>
          <v-list-item id="logout" class="px-5 text-subtitle-2" @click="$auth.logout()">
            <v-list-item-content>
              Log Out
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-4">
      <v-layout column class="pa-5 content-height">
        <v-card class="d-flex flex-column h-full pa-5 overflow-y-auto rounded-lg">
          <h1 class="mb-0 pb-4 text-h5">
            {{ pageHeading($route.path) }}
          </h1>
          <v-divider class="mb-6" />

          <nuxt />
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'LayoutAdmin',

  middleware({ store, redirect }) {
    const isAdmin = store.state.auth.user && (store.state.auth.user.role === 'admin')
    if (!isAdmin) {
      return redirect('/')
    }
  },

  data: () => ({
    drawer: true,
    mini: false,
    items: [
      {
        text: 'Dashboards',
        icon: 'home',
        to: '/admin/dashboard'
      },
      {
        headline: 'Content Management',
        children: [
          {
            text: 'Curated Content',
            icon: 'article',
            to: '/admin/curated-contents'
          },
          {
            text: 'Graphic Content',
            icon: 'image',
            to: '/admin/graphic-contents'
          },
          {
            text: 'Form Content',
            icon: 'description',
            to: '/admin/form-contents'
          },
          {
            text: 'Sample Listings',
            icon: 'list',
            to: '/admin/listings'
          },
          {
            text: 'Product Library',
            icon: 'mdi-shopping',
            to: '/admin/product-templates'
          }
        ]
      },
      {
        headline: 'Tags',
        children: [
          {
            text: 'Content Tags',
            icon: 'local_offer',
            to: '/admin/content-tags'
          },

          {
            text: 'Template Tags',
            icon: 'label',
            to: '/admin/template-tags'
          }
        ]
      },
      {
        headline: 'Templates',
        children: [
          {
            text: 'Templates',
            icon: 'description',
            to: '/admin/templates'
          },
          {
            text: 'Design Templates',
            icon: 'art_track',
            to: '/admin/templates-design'
          },
          {
            text: 'Sales Page Templates',
            icon: 'web',
            to: '/admin/sales-page-template'
          },
          {
            text: 'Promotional Content Templates',
            icon: 'shopping_bag',
            to: '/admin/promotional-content-templates'
          }
        ]
      },
      {
        headline: 'Pricing & Payment',
        children: [
          {
            text: 'Roles',
            icon: 'supervisor_account',
            to: '/admin/roles'
          },
          {
            text: 'Pricing Tables',
            icon: 'table_chart',
            to: '/admin/pricing-table'
          },
          {
            text: 'Coupons',
            icon: 'mdi-ticket-percent-outline',
            to: '/admin/coupons'
          }
        ]
      },
      {
        headline: 'Copies & Notes',
        children: [
          {
            text: 'Copies',
            icon: 'format_align_left',
            to: '/admin/textcopies'
          },
          {
            text: 'Action Notes',
            icon: 'notes',
            to: '/admin/notes'
          }
        ]
      },
      {
        headline: 'User & Feedback',
        children: [
          {
            text: 'Users',
            icon: 'person',
            to: '/admin/users'
          },
          {
            text: 'Checklists',
            icon: 'checklist',
            to: '/admin/checklists'
          },
          {
            text: 'Cancellation Feedback',
            icon: 'feedback',
            to: '/admin/cancellation-feedbacks'
          }
        ]
      },
      {
        headline: 'Settings & Tools',
        children: [
          {
            text: 'Settings',
            icon: 'settings',
            to: '/admin/settings'
          },
          {
            text: 'Tools',
            icon: 'construction',
            children: [
              {
                text: 'Tasks',
                to: '/admin/task'
              },
              {
                text: 'Databases',
                to: '/admin/database'
              }
            ]
          }
        ]
      }
    ]
  }),

  // computed: {
  //   allItems() {
  //     return this.items.reduce((acc, item) => {
  //       if (item.headline) {
  //         acc.push(item.headline)
  //       }
  //       if (item.children) {
  //         acc.push(...item.children.map(child => child.text))
  //       }
  //       return acc
  //     }, [])
  //   }
  // },

  methods: {
    findItemsRecursive(arr) {
      const itemsWithTo = []
      for (const item of arr) {
        if (item.to) {
          itemsWithTo.push({ text: item.text, to: item.to })
        }
        if (item.children && Array.isArray(item.children)) {
          itemsWithTo.push(...this.findItemsRecursive(item.children))
        }
      }
      return itemsWithTo
    },
    pageHeading(routerPath) {
      const allItems = this.findItemsRecursive(this.items)
      return allItems.find(item => routerPath === item.to)?.text || allItems.find(item => routerPath.includes(item.to))?.text || ''
    }
  }
}
</script>
<style lang="scss" scoped>
#logout {
  color: var(--v-error-base) !important;
}
.content-height {
  height: calc(100vh - 56px);
}
.app-bar {
  box-shadow: 0 2px 4px rgba(0,0,20,.08), 0 1px 2px rgba(0,0,20,.08) !important;
}
</style>
