import { get } from 'lodash'

export default function ({ $axios, error, store, $cookies, $notify }) {
  // $axios.onError((error) => {
  //   let errorMessage = ''
  //   let errorCode
  //   if (error && typeof error === 'object') {
  //     errorMessage = get(error, 'response.data.message') || get(error, 'response.data.error')
  //     errorCode = get(error, 'response.data.statusCode')
  //   }
  //   errorMessage = errorMessage || String(error)
  //   try {
  //     const errorObj = JSON.parse(errorMessage)
  //     if (errorCode === 403 && Object.keys(errorObj).length) {
  //       console.log('errorObj', errorObj)
  //       return Promise.resolve(false)
  //     }
  //     return Promise.reject(error)
  //   } catch (err) {
  //     return Promise.reject(error)
  //   }
  // })

  $axios.downloadCSV = async (url, config = {}, fileName = 'file') => {
    const data = await $axios.$get(url, {
      ...config,
      headers: {
        ...(config.headers || {}),
        Accept: 'text/csv'
      },
      responseType: 'blob'
    })

    // create file link in browser's memory
    const href = URL.createObjectURL(data)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', fileName + '.csv') // or any other extension
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  $axios.onRequest((config) => {
    try {
      $axios.setHeader('license-token', $cookies.get('license-token'))
      $axios.setHeader('membership-session-id', $cookies.get('membership-session-id'))
      $axios.setHeader('user-subdomain', store.getters.userSubdomain)
    } catch (error) {
      $cookies.remove('license-token')
    }
    store.commit('setRequesting', true)
  })

  $axios.onResponse((response) => {
    const headerAppCookie = response.headers['app-cookie']
    if (headerAppCookie && $cookies.set) {
      try {
        const cookie = JSON.parse(headerAppCookie)
        $cookies.set(cookie.name, cookie.value, {
          ...cookie.options,
          httpOnly: false,
          secure: false,
          path: '/'
        })
      } catch (error) { }
    }
    const headerUnreadNotification = response.headers['unread-notification']
    if (headerUnreadNotification) {
      store.commit('notification/setUnreadCount', headerUnreadNotification)
    }
    store.commit('setRequesting', false)
  })

  $axios.onError((err) => {
    const code = parseInt(get(err, 'response.status'))
    const isClientRequest = get(err, 'request.constructor.name') === 'ClientRequest'
    const errorMessage = get(err, 'response.data.message') || get(err, 'response.data.error') || get(err, 'response.statusText')

    if ((isClientRequest && [400, 404].includes(code)) || [500].includes(code)) {
      error({ statusCode: code, message: errorMessage })
    }

    if (process.client && get(err, 'response.data.rolePermission')) {
      window.$nuxt.$emit('permission', {
        message: errorMessage,
        ...get(err, 'response.data', {})
      })
      setTimeout($notify.destroy, 100)
    }

    store.commit('setRequesting', false)

    return error.response
  })
}
