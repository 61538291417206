const middleware = {}

middleware['debug_auth'] = require('../middleware/debug_auth.js')
middleware['debug_auth'] = middleware['debug_auth'].default || middleware['debug_auth']

middleware['notification'] = require('../middleware/notification.js')
middleware['notification'] = middleware['notification'].default || middleware['notification']

middleware['setting'] = require('../middleware/setting.js')
middleware['setting'] = middleware['setting'].default || middleware['setting']

middleware['user-subdomain'] = require('../middleware/user-subdomain.js')
middleware['user-subdomain'] = middleware['user-subdomain'].default || middleware['user-subdomain']

export default middleware
